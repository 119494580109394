var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-0",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      _vm.analyzingImage
        ? _c(
            "span",
            [
              _c("loading", {
                attrs: {
                  active: _vm.analyzingImage,
                  loader: "bars",
                  "is-full-page": "",
                  color: "#00A09C",
                },
                on: {
                  "update:active": function ($event) {
                    _vm.analyzingImage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.analyzingImage && _vm.isNoAnalyzeImgResult
        ? _c("b-alert", { attrs: { show: "", dismissible: "", fade: "" } }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.FormMSG(
                    9875678,
                    "No billing information detected in this image"
                  )
                ) +
                "\n\t"
            ),
          ])
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c("date-description", {
                attrs: { "edit-data": _vm.expense },
                on: { change: _vm.handleDateAndDescriptionChanges },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.rendReferenceLabel,
                    "label-for": "new-expense_ticket-ref",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "new-expense_ticket-ref",
                          value: _vm.currNewTicketRef
                            ? _vm.currNewTicketRef
                            : _vm.expense
                            ? _vm.expense.id
                            : "",
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c(_vm.getLucideIcon("Ticket"), {
                                tag: "component",
                                attrs: {
                                  color: "#00A09C",
                                  size: 20,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _vm.$props.useComponent
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(70, "Select Person"),
                        "label-for": "new-expense_ticket-ref",
                      },
                    },
                    [
                      _c("b-form-select", {
                        class: {
                          "is-invalid": _vm.$v.newTicket.userToEncodedId.$error,
                        },
                        attrs: {
                          size: "md",
                          "value-field": "value",
                          "text-field": "name",
                          "disabled-field": "notEnabled",
                          options: _vm.users,
                          disabled: _vm.disableSelectUser,
                        },
                        on: { change: _vm.handleSelectUser },
                        model: {
                          value: _vm.newTicket.userToEncodedId,
                          callback: function ($$v) {
                            _vm.$set(_vm.newTicket, "userToEncodedId", $$v)
                          },
                          expression: "newTicket.userToEncodedId",
                        },
                      }),
                      _vm.$v.newTicket.userToEncodedId.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    79,
                                    "Please, the user to encoded is required"
                                  )
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "my-2" },
        [
          _c(
            "b-col",
            [
              _c("captures-package", {
                attrs: {
                  multiple: "",
                  "preview-mode": "button",
                  "parent-type": "expense_item",
                  "parent-sub-type": "ticket",
                  "for-new-expense": _vm.isNew,
                  "parent-id": _vm.currNewTicketRef,
                  pictures: _vm.rendImagesListForPreviewer,
                  "add-image-disabled": _vm.analyzingImage,
                  "preview-modal-options": {
                    zIndex: 1041,
                    hideLikeDislike: true,
                    hideCommentButton: true,
                    hideDisLikeButton: true,
                  },
                },
                on: {
                  "update:parentId": function ($event) {
                    _vm.currNewTicketRef = $event
                  },
                  "update:parent-id": function ($event) {
                    _vm.currNewTicketRef = $event
                  },
                  "update:pictures": function ($event) {
                    _vm.rendImagesListForPreviewer = $event
                  },
                  "update:addImageDisabled": function ($event) {
                    _vm.analyzingImage = $event
                  },
                  "update:add-image-disabled": function ($event) {
                    _vm.analyzingImage = $event
                  },
                  change: _vm.handleImgsTaken,
                  "capture-modal:close-previewer":
                    _vm.handleCaptureModalClosePreviewer,
                  "upload-image:loading": _vm.handleUploadImgStatus,
                  "upload-image:error": _vm.handleImageUploadError,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c("supplier-selector", {
                        attrs: {
                          title: `${
                            !_vm.isSupplierUpdate
                              ? _vm.FormMSG(250, "Add supplier details")
                              : _vm.FormMSG(251, "Update supplier details")
                          }`,
                          label: _vm.FormMSG(4, "Name of a supplier"),
                          placeholder: _vm.FormMSG(5445, "Filter by name"),
                          "supplier-id": _vm.supplierId,
                          "ocr-suppliers": _vm.suppliersInImages,
                          "use-new-design": "",
                          version: "1.0",
                        },
                        on: {
                          "supplier:unselected": _vm.onUnselected,
                          change: _vm.handleSupplierChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(5, "Type of paiement"),
                            "label-for": "new-expense_ticket-paiement-type",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "new-expense_ticket-paiement-type",
                              options: _vm.mapPaiementTypes,
                            },
                            model: {
                              value: _vm.newTicket.paiementType,
                              callback: function ($$v) {
                                _vm.$set(_vm.newTicket, "paiementType", $$v)
                              },
                              expression: "newTicket.paiementType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c(
                "b-form-group",
                {
                  staticStyle: { "margin-top": "12px" },
                  attrs: {
                    label: _vm.FormMSG(9989898, "Description"),
                    "label-for": "new-expense_description",
                  },
                },
                [
                  _c("b-form-textarea", {
                    class: {
                      "is-invalid": _vm.$v.newTicket.description.$error,
                    },
                    staticStyle: { height: "6.25rem" },
                    attrs: { id: "new-expense_description" },
                    model: {
                      value: _vm.$v.newTicket.description.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.newTicket.description, "$model", $$v)
                      },
                      expression: "$v.newTicket.description.$model",
                    },
                  }),
                  _vm.$v.newTicket.description.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(76, "Please, reference is required")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c("department-selection", {
                attrs: {
                  "edit-data": _vm.expense,
                  "form-type": _vm.formType,
                  "remove-dep-zero": "",
                  "is-new": _vm.isNew,
                  "department-desktop-size": "6",
                  "category-desktop-size": "6",
                  "is-submitted": _vm.isSubmitted,
                  "for-expense": "",
                },
                on: {
                  change: _vm.handleDepartmentAndCatChange,
                  "department:selector:invalid": _vm.isInvalidateFields,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(6, "Total amount"),
                    "label-for": "new-expense_amount-total",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid":
                            _vm.isInvalid ||
                            _vm.$v.newTicket.amountTotal.$error,
                        },
                        attrs: {
                          id: "new-expense_amount-total",
                          type: "number",
                          placeholder: "0.00",
                          step: "0.01",
                        },
                        on: { input: _vm.handleAmountTotalChange },
                        model: {
                          value: _vm.$v.newTicket.amountTotal.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.newTicket.amountTotal,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.newTicket.amountTotal.$model",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isInvalid || _vm.$v.newTicket.amountTotal.$error
                    ? _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(
                                25011,
                                "Must be greater than VAT amount"
                              )
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(74, "VAT"),
                    "label-for": "type-of-vat",
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      label: "text",
                      options: _vm.taxOptions,
                      reduce: (option) => option.value,
                      "get-option-label": (option) =>
                        option.item ? option.item.factor : "",
                      placeholder: _vm.FormMSG(75, "Select a VAT"),
                      selectable: _vm.selectableItem,
                    },
                    on: { input: _vm.handleVatChange },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            option.disabled
                              ? _c("div", { staticClass: "text-bold" }, [
                                  _vm._v(_vm._s(option.text)),
                                ])
                              : _c("div", { staticClass: "text-color ml-3" }, [
                                  _vm._v(
                                    _vm._s(option.text) +
                                      " - " +
                                      _vm._s(option.item.name) +
                                      " (" +
                                      _vm._s(option.item.factor) +
                                      "%)"
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.vatType,
                      callback: function ($$v) {
                        _vm.vatType = $$v
                      },
                      expression: "vatType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(7, "VAT amount"),
                    "label-for": "new-expense_amout-vat",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        class:
                          _vm.isInvalid &&
                          parseFloat(_vm.newTicket.amountTotal) > 0
                            ? "is-invalid"
                            : "",
                        attrs: {
                          id: "new-expense_amout-vat",
                          max: _vm.newTicket.amountTotal,
                          type: "number",
                          placeholder: "0.00",
                          step: "0.01",
                          disabled: _vm.vatAmountDisabled,
                        },
                        model: {
                          value: _vm.newTicket.amountVat,
                          callback: function ($$v) {
                            _vm.$set(_vm.newTicket, "amountVat", $$v)
                          },
                          expression: "newTicket.amountVat",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isInvalid && parseFloat(_vm.newTicket.amountTotal) > 0
                    ? _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.FormMSG(
                              25021,
                              "Must be smaller than total amount"
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.canEditExpense && _vm.flags.length > 0
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "fieldset",
                  {
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside my-0 py-0 pb-0"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _c(
                      "legend",
                      {
                        staticClass: "text-color-rhapsody-in-blue-2",
                        class: `${
                          _vm.$screen.width <= 576
                            ? "card-inside"
                            : "scheduler-border"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(22, "Flags")) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-2 px-0" },
                      _vm._l(_vm.flags, function (flag, index) {
                        return _c(
                          "b-col",
                          {
                            key: index,
                            staticClass: "d-flex mb-2 w-100",
                            attrs: { sm: _vm.sizeFlagInput(_vm.flags.length) },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "w-100",
                                attrs: { label: `${flag.name.toUpperCase()}` },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    label: "name",
                                    placeholder: "...",
                                    options: flag.projectFlagItem,
                                    reduce: (option) => option.id,
                                    disabled: !_vm.canEditExpense,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.flagChanged(
                                        $event,
                                        flag.flagId
                                      )
                                    },
                                  },
                                  model: {
                                    value: flag.customFlagSelected,
                                    callback: function ($$v) {
                                      _vm.$set(flag, "customFlagSelected", $$v)
                                    },
                                    expression: "flag.customFlagSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("duplicate-expenses-warning", {
        attrs: { "ocr-message-error": _vm.ocrMessageError },
        on: {
          "expenses-warning:ok": function ($event) {
            _vm.ocrIssueModal = false
          },
        },
        model: {
          value: _vm.ocrIssueModal,
          callback: function ($$v) {
            _vm.ocrIssueModal = $$v
          },
          expression: "ocrIssueModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }