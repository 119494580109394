var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "po-item-not-consumed-item" },
    [
      _c(
        "b-card",
        { class: _vm.rendCardClass, on: { click: _vm.handleSelectItem } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "1" } }, [
                _c(
                  "span",
                  { staticClass: "name" },
                  [
                    _c("b-form-checkbox", {
                      attrs: { size: "lg", id: "toEmail", disabled: true },
                      model: {
                        value: _vm.item.selected,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "selected", $$v)
                        },
                        expression: "item.selected",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("b-col", { attrs: { md: "7" } }, [
                _c("span", { staticClass: "name" }, [
                  _c("b", { domProps: { innerHTML: _vm._s(_vm.item.name) } }),
                ]),
              ]),
              _c("b-col", { attrs: { md: "4" } }, [
                _c("span", { staticClass: "total" }, [
                  _vm._v(_vm._s(_vm.rendTotal)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }