<template>
	<div class="px-0" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<!-- <pre>{{ newTicket }}</pre> -->
		<!-- <pre>{{ expense }}</pre> -->
		<span v-if="analyzingImage">
			<loading :active.sync="analyzingImage" loader="bars" is-full-page color="#00A09C" />
		</span>
		<b-alert v-if="!analyzingImage && isNoAnalyzeImgResult" show dismissible fade>
			{{ FormMSG(9875678, 'No billing information detected in this image') }}
		</b-alert>
		<b-row>
			<b-col sm="6">
				<date-description :edit-data="expense" @change="handleDateAndDescriptionChanges" />
			</b-col>
			<b-col sm="6">
				<b-form-group :label="rendReferenceLabel" label-for="new-expense_ticket-ref">
					<b-input-group>
						<b-form-input id="new-expense_ticket-ref" :value="currNewTicketRef ? currNewTicketRef : expense ? expense.id : ''" disabled />
						<b-input-group-append>
							<b-input-group-text>
								<component :is="getLucideIcon('Ticket')" color="#00A09C" :size="20" :stroke-width="2.25" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12">
				<b-form-group v-if="$props.useComponent" :label="FormMSG(70, 'Select Person')" label-for="new-expense_ticket-ref">
					<b-form-select
						size="md"
						v-model="newTicket.userToEncodedId"
						value-field="value"
						text-field="name"
						disabled-field="notEnabled"
						:options="users"
						:disabled="disableSelectUser"
						@change="handleSelectUser"
						:class="{ 'is-invalid': $v.newTicket.userToEncodedId.$error }"
					/>
					<div v-if="$v.newTicket.userToEncodedId.$error" class="invalid-feedback">
						{{ FormMSG(79, 'Please, the user to encoded is required') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row class="my-2">
			<b-col>
				<captures-package
					multiple
					preview-mode="button"
					parent-type="expense_item"
					parent-sub-type="ticket"
					:for-new-expense="isNew"
					:parent-id.sync="currNewTicketRef"
					:pictures.sync="rendImagesListForPreviewer"
					:add-image-disabled.sync="analyzingImage"
					:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
					@change="handleImgsTaken"
					@capture-modal:close-previewer="handleCaptureModalClosePreviewer"
					@upload-image:loading="handleUploadImgStatus"
					@upload-image:error="handleImageUploadError"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="6">
				<b-row class="mt-2">
					<b-col>
						<supplier-selector
							:title="`${!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')}`"
							:label="FormMSG(4, 'Name of a supplier')"
							:placeholder="FormMSG(5445, 'Filter by name')"
							:supplier-id="supplierId"
							:ocr-suppliers="suppliersInImages"
							@supplier:unselected="onUnselected"
							@change="handleSupplierChange"
							use-new-design
							version="1.0"
						/>
					</b-col>
				</b-row>
				<b-row class="mt-1">
					<b-col>
						<b-form-group :label="FormMSG(5, 'Type of paiement')" label-for="new-expense_ticket-paiement-type">
							<b-form-select id="new-expense_ticket-paiement-type" v-model="newTicket.paiementType" :options="mapPaiementTypes" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>
			<b-col sm="6">
				<b-form-group :label="FormMSG(9989898, 'Description')" label-for="new-expense_description" style="margin-top: 12px">
					<b-form-textarea
						id="new-expense_description"
						v-model="$v.newTicket.description.$model"
						style="height: 6.25rem"
						:class="{
							'is-invalid': $v.newTicket.description.$error
						}"
					/>
					<div v-if="$v.newTicket.description.$error" class="invalid-feedback">
						{{ FormMSG(76, 'Please, reference is required') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12">
				<department-selection
					:edit-data="expense"
					:form-type="formType"
					remove-dep-zero
					:is-new="isNew"
					department-desktop-size="6"
					category-desktop-size="6"
					:is-submitted="isSubmitted"
					@change="handleDepartmentAndCatChange"
					@department:selector:invalid="isInvalidateFields"
					for-expense
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-form-group :label="FormMSG(6, 'Total amount')" label-for="new-expense_amount-total">
					<!-- <pre>{{ $v.newTicket }}</pre> -->
					<b-input-group>
						<b-form-input
							id="new-expense_amount-total"
							:class="{
								'is-invalid': isInvalid || $v.newTicket.amountTotal.$error
							}"
							v-model="$v.newTicket.amountTotal.$model"
							type="number"
							placeholder="0.00"
							step="0.01"
							@input="handleAmountTotalChange"
						/>
						<b-input-group-append>
							<b-input-group-text>
								<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<small v-if="isInvalid || $v.newTicket.amountTotal.$error" style="color: red">
						{{ FormMSG(25011, 'Must be greater than VAT amount') }}
					</small>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group :label="FormMSG(74, 'VAT')" label-for="type-of-vat">
					<v-select
						v-model="vatType"
						label="text"
						:options="taxOptions"
						:reduce="(option) => option.value"
						:get-option-label="(option) => (option.item ? option.item.factor : '')"
						:placeholder="FormMSG(75, 'Select a VAT')"
						:selectable="selectableItem"
						@input="handleVatChange"
					>
						<template #option="option">
							<div class="text-bold" v-if="option.disabled">{{ option.text }}</div>
							<div class="text-color ml-3" v-else>{{ option.text }} - {{ option.item.name }} ({{ option.item.factor }}%)</div>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group :label="FormMSG(7, 'VAT amount')" label-for="new-expense_amout-vat">
					<b-input-group>
						<b-form-input
							:class="isInvalid && parseFloat(newTicket.amountTotal) > 0 ? 'is-invalid' : ''"
							id="new-expense_amout-vat"
							:max="newTicket.amountTotal"
							v-model="newTicket.amountVat"
							type="number"
							placeholder="0.00"
							step="0.01"
							:disabled="vatAmountDisabled"
						/>
						<b-input-group-append>
							<b-input-group-text>
								<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<small v-if="isInvalid && parseFloat(newTicket.amountTotal) > 0" style="color: red">{{
						FormMSG(25021, 'Must be smaller than total amount')
					}}</small>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row v-if="canEditExpense && flags.length > 0">
			<b-col>
				<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
					<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
						{{ FormMSG(22, 'Flags') }}
					</legend>
					<b-row class="mt-2 px-0">
						<b-col :sm="sizeFlagInput(flags.length)" class="d-flex mb-2 w-100" v-for="(flag, index) in flags" :key="index">
							<b-form-group :label="`${flag.name.toUpperCase()}`" class="w-100">
								<v-select
									v-model="flag.customFlagSelected"
									label="name"
									placeholder="..."
									:options="flag.projectFlagItem"
									:reduce="(option) => option.id"
									:disabled="!canEditExpense"
									@input="flagChanged($event, flag.flagId)"
								/>
							</b-form-group>
						</b-col>
					</b-row>
				</fieldset>
			</b-col>
		</b-row>
		<!-- modal warning if ticket is duplicated-->
		<duplicate-expenses-warning
			:ocr-message-error="ocrMessageError"
			v-model="ocrIssueModal"
			@expenses-warning:ok="ocrIssueModal = false"
		></duplicate-expenses-warning>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import { isNil, objReducer, greaterThanZero, amountTotalValid } from '@/shared/utils';
import mapProps from '@/shared/vuePropsMapper';
import languageMessages from '@/mixins/languageMessages';
import DateDescription from '@/components/ExpenseService/DateSelection';
import SupplierSelector from '@/components/SupplierSelector';
import CapturesPackage from '@/components/Packages/Captures/components/Main';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import { getFileExtension } from '@/shared/helpers';
import { getUsers } from '@/cruds/users.crud';
import { expenseItemsFromImageOrPdf, checkIfCarbonRowDuplicate } from '@/cruds/expense.crud';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import moment from 'moment';
import _ from 'lodash';
import { VALIDATION_TYPE, EXPENSE_CARBON_ENTITY_TYPE } from '@/shared/constants';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import CurrencySvg from '@/components/Svg/Currency';
// import modal
import DuplicateExpensesWarning from '@/components/Modals/DuplicateExpensesWarningModal';
import { noTilde } from '~utils';
// for flags
import expensesFlagsMixin from '@/mixins/expensesFlags.mixin';

const defaultTicket = {
	supplierName: '',
	department: null,
	category: null,
	amountTotal: 0,
	amountVat: 0,
	paiementType: 0,
	description: '',
	images: [],
	flagItemId: 0,
	projectFlagItems: null
};

export default {
	name: 'CreateNewExpenseTicketForm',
	components: {
		DuplicateExpensesWarning,
		DateDescription,
		Loading,
		// GetPictureFromCameraPwaBtn,
		CapturesPackage,
		departmentSelection: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		// Capture,
		SupplierSelector,
		CoTwoClap,
		CurrencySvg
	},
	mixins: [languageMessages, globalMixin, validationMixin, expensesFlagsMixin],
	props: {
		...mapProps(['formType', 'expenseType'], {
			type: Number,
			required: false,
			default: 0 // - ticket: 0, invoice: 1
		}),
		userValue: {
			type: Object,
			required: false
		},
		useComponent: {
			type: Boolean,
			default: false,
			required: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		isSubmitted: {
			type: Boolean,
			required: true,
			default: false
		},
		isNew: {
			type: Boolean
		}
	},
	data() {
		return {
			isCaptureModalOpen: false,
			show: true,
			newTicket: Object.assign({}, defaultTicket),
			analyzingImage: false,
			suppliersInImages: [],
			isNoAnalyzeImgResult: false,
			isSupplierUpdate: false,
			uploadImgStatus: false,
			user: {
				name: ''
			},
			disableSelectUser: false,
			users: [{ value: 0, name: 'Select ...' }],
			userList: [],
			allowExpenseEntryOutsideContract: 0,
			messageAllowExpenseEntryOutsideContract: '',
			dateSelected: moment(Date.now()).format('YYYY-MM-DD') + 'T00:00:00Z',
			showPopUp: false,
			showAlert: false,
			parentIdCoTwoClap: 0,
			forCreateCoTwoClap: true,
			submitCarbon: false,
			vatType: null,
			originalVatAmount: 0,
			vatAmountDisabled: false,
			vatValueChoosed: null,
			expense: null,
			validationType: 0,
			ocrIssueModal: false,
			ocrMessageError: '',
			userList: []
		};
	},
	computed: {
		isInvalid() {
			if (this.newTicket.amountTotal === '') this.newTicket.amountTotal = 0;
			if (this.newTicket.amountVat === '') this.newTicket.amountVat = 0;

			return !(parseFloat(this.newTicket.amountTotal) !== 0 && parseFloat(this.newTicket.amountTotal) > parseFloat(this.newTicket.amountVat));
		},
		...mapGetters({
			currNewTicketRef: 'expenses/currTicketRef',
			selectedPO: 'purchaces/purchaseOrder',
			taxOptions: 'flag/taxOptions'
		}),
		/**
		 * @return {String}
		 */
		rendReferenceLabel() {
			const _C = this.isInvoiceTicket;
			const _t = this.FormMSG;

			const t = {
				i: _t(1, 'Invoice'),
				t: _t(2, 'Ticket')
			};
			return [t[_C ? 'i' : 't'], _t(3, 'reference')].join(' ');
		},
		mapPaiementTypes() {
			let menus = this.FormMenu(1005);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		isInvoiceTicket: {
			get() {
				return this.expenseType === 1;
			},
			set(val) {
				return val;
			}
		},
		supplierId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (isNil(this.expense) || parseInt(this.expense.supplierId, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.expense.supplierId;
		},
		/**
		 * @return {Array}
		 */
		rendImagesListForPreviewer() {
			if (this.newTicket.images.length === 0) return [];
			return this.newTicket.images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));
		},
		setAllowExpenseEntryOutsideContract() {
			return store.getCurrentProjectConfig().allowExpenseEntryOutsideContract;
		}
	},
	watch: {
		editData: {
			handler(newVal) {
				if (!_.isNil(newVal)) {
					this.expense = newVal;
					this.parentIdCoTwoClap = +newVal.id;
					this.forCreateCoTwoClap = false;
					this.validationType = this.expense.validated;

					this.initEditMode();
				} else {
					this.forCreateCoTwoClap = true;
				}
			},
			immediate: true,
			deep: true
		},
		isSubmitted: {
			handler(newVal) {
				// console.log({ isSubmitted: newVal });
			},
			immediate: true,
			deep: true
		},
		/**
		 * @param {Object} ticket
		 */
		userValue: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		newTicket: {
			handler(ticket) {
				this.emitChange(ticket);
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.$nextTick(async () => {
			this.initVat();
			this.handleSelectUser(this.newTicket.userToEncodedId);
			this.setUser();
			await this.getAllUsers();
			await this.getTaxOptions();
		});
	},
	async mounted() {
		this.$v.$touch();

		this.$bus.$on('filepreviewer:image-deleted-from-parent', (payload) => {
			if (isNil(this.newTicket)) return;
			this.newTicket.images = isNil(payload.refreshedImages) ? [] : payload.refreshedImages;
		});
		this.$bus.$on('submitted:action-add-update-expense', async (entityId) => {
			await this.updateExpenseFlagItems(entityId, this.vatType, this.taxOptions);
		});

		// get custom flags
		this.isNew ? await this.setDefaultCustomFlags(this.newTicket) : await this.setDefaultCustomFlags(this.expense);
	},
	destroyed() {
		// shutdown event bus
		this.$bus.$off('filepreviewer:image-deleted-from-parent');
		this.$bus.$off('submitted:action-add-update-expense');
	},
	methods: {
		...mapActions({
			getTaxOptions: 'flag/getTaxOptions'
		}),
		flagChanged(payload, flagId) {
			this.customFlagChanged(payload, flagId);
			this.isNew ? (this.newTicket.flagItemId = +payload) : (this.expense.flagItemId = +payload);
		},
		handleCaptureModalClosePreviewer() {
			this.$emit('ticket:imgs-taken');
		},
		// handleCoTwoClapEndSubmit() {},
		handleSelectUser(id) {
			let user = this.userList.find((user) => user.id === id);
			if (user && user.department !== 0 && user.departmentName !== '') {
				this.expense = {
					...this.expense, // Spread the existing properties
					department: user.department, // Overwrite or add the department property
					departmentName: user.departmentName // Overwrite or add the departmentName property
				};
			}
			this.$emit('changeUser', id);
			return;
		},
		setUser() {
			if (this.expense && this.useComponent === true) {
				this.disableSelectUser = true;
				this.newTicket.userToEncodedId = this.expense.user.id;
				this.$emit('changeUser', this.newTicket.userToEncodedId);
				return;
			}

			if (!this.expense && this.useComponent === true) {
				if (this.userValue.value !== undefined) {
					this.newTicket.userToEncodedId = this.userValue.value;
					this.$emit('changeUser', this.newTicket.userToEncodedId);
					return;
				} else {
					this.newTicket.userToEncodedId = store.state.myProfile.id;
					this.$emit('changeUser', this.newTicket.userToEncodedId);
					return;
				}
			}

			if (this.expense) {
				this.newTicket.userToEncodedId = +this.expense.user.id;
				this.$emit('changeUser', this.newTicket.userToEncodedId);
				return;
			} else {
				this.newTicket.userToEncodedId = store.state.myProfile.id;
				this.$emit('changeUser', this.newTicket.userToEncodedId);
				return;
			}
		},
		async getAllUsers() {
			let forExpense = true;

			const usersData = await getUsers({ myProject: true }, 0, -1, 0, 0, '', '', null, null, forExpense);
			this.userList = usersData;
			this.users = [
				...this.users,
				...[...usersData].map((_user) => {
					return { value: _user.id, name: _user.fullName };
				})
			];

			if (this.useComponent) {
				this.users = this.users.filter((item) => +item.value !== +store.state.myProfile.id);
			}
			let isResult = this.users.find((item) => +item.value === +this.newTicket.userToEncodedId);
			if (isResult === undefined) {
				this.newTicket.userToEncodedId = 0;
				this.$emit('changeUser', this.newTicket.userToEncodedId);
			} else {
				this.$emit('changeUser', this.newTicket.userToEncodedId);
			}
		},
		initEditMode() {
			if (isNil(this.expense)) return;
			const omitFields = Object.keys(defaultTicket);
			const obj = objReducer(omitFields, this.expense);
			this.setTicketData(obj);
		},
		/**
		 * @param {Object|Array} imagesData
		 */
		async handleImgsTaken(imagesData) {
			this.analyzingImage = true;
			this.ocrMessageError = '';

			if (
				+this.validationType === VALIDATION_TYPE.NOT_SUBMITTED ||
				+this.validationType === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
				+this.validationType === VALIDATION_TYPE.REFUSED_LEVEL_2
			) {
				// const cleanImgs = images.map((i) => i.replace(/\.[^\.]*$/, ''));

				// check if we need to pass the images list to the OCR or not.
				let imgsForOcr = [];
				let imgsList = [];
				if (Array.isArray(imagesData)) {
					imgsList = imagesData;
				} else if (typeof imagesData === 'object' && imagesData !== null) {
					imgsForOcr = imagesData.imagesForOcr;
					imgsList = imagesData.images;
				}
				/**
				 * remove ext from xid files to pass it to the OCR if needed..
				 * @type {Array} cleanImgs
				 */
				const cleanNewImagesListForOcr = imgsForOcr.filter((item) => !this.newTicket.images.includes(item)).map((i) => i.replace(/\.[^\.]*$/, ''));

				try {
					if (cleanNewImagesListForOcr.length > 0) {
						const [newExpense] = await expenseItemsFromImageOrPdf(cleanNewImagesListForOcr);
						this.newTicket = {
							...this.newTicket,
							...newExpense,
							department: this.newTicket.department
						};
						this.expense = {
							...this.expense,
							...this.newTicket
						};
						const isDataDuplicated = await checkIfCarbonRowDuplicate(this.expense.id, EXPENSE_CARBON_ENTITY_TYPE);
						if (isDataDuplicated) {
							this.ocrMessageError = this.FormMSG(99, 'DUPLICATE ENTRY: It seems like similar data has already been used in another entry.');
							this.ocrIssueModal = true;
						}
					}

					this.newTicket.images = imgsList;
					this.$emit('payload-refreshed', this.newTicket);
				} catch (error) {
					console.error({ error });

					const { graphQLErrors } = error;
					if (!_.isNil(graphQLErrors) && !_.isUndefined(graphQLErrors) && graphQLErrors.length > 0) {
						this.ocrMessageError = graphQLErrors[0].message;
					}

					// this.newTicket.images.pop();
					this.ocrIssueModal = true;
					this.analyzingImage = false;
				}
				// await this.setOcrAnalyses(images);
			}

			this.analyzingImage = false;
			this.$emit('ticket:imgs-taken');
		},
		/**
		 * Merge some data to newticket element
		 * @param {Object} obj
		 */
		setTicketData(obj) {
			this.newTicket = Object.assign({}, defaultTicket, this.newTicket, obj);
			if (!isNil(this.expense) && !isNil(this.expense.user)) {
				this.newTicket.userToEncodedId = +this.expense.user.id;
			}
		},
		emitChange(ticket) {
			this.$emit(
				'change',
				{
					...ticket,
					amountTotal: parseFloat(ticket.amountTotal),
					amountVat: parseFloat(ticket.amountVat)
				},
				this.$v.$invalid
			);
		},
		handleDepartmentAndCatChange(element) {
			this.setTicketData(element);
		},
		handleDateAndDescriptionChanges(obj) {
			const _o = objReducer(['date'], obj);
			this.dateSelected = _o.date;
			this.setTicketData(_o);
		},
		/**
		 * @param {Object} supplier
		 */
		handleSupplierChange(supplier) {
			this.isSupplierUpdate = false;

			if (isNil(supplier)) return;

			this.isSupplierUpdate = true;
			const { id, supplierName } = supplier;
			this.setTicketData({
				supplierId: parseInt(id),
				supplierName: supplierName
			});
		},
		handleUploadImgStatus(status) {
			this.$emit('upload-image-loading:status', status);
		},
		isInvalidateFields(payload) {
			this.$emit('ticket:invalid', payload);
		},
		onUnselected() {
			this.isSupplierUpdate = false;
		},
		selectableItem(item) {
			return !item.disabled;
		},
		handleVatChange(payload) {
			this.vatValueChoosed = payload;

			if (!_.isNil(payload)) {
				// console.log({ payload, taxOptions: this.taxOptions.filter((tax) => +payload === +tax.item.id && !tax.disabled)[0].item.factor });
				this.calculateVatAmount(payload);
				this.vatAmountDisabled = true;
				this.newTicket.flagItemId = +payload;
			} else {
				this.newTicket.amountVat = this.originalVatAmount;
				this.vatAmountDisabled = false;
				this.newTicket.flagItemId = 0;
			}

			this.$v.$touch();
		},
		initVat() {
			this.originalVatAmount = !_.isNil(this.expense) ? this.expense.amountVat : 0;
			this.newTicket.flagItemId = 0;

			if (
				!_.isUndefined(this.newTicket.projectFlagItems) &&
				!_.isNil(this.newTicket.projectFlagItems) &&
				this.newTicket.projectFlagItems.taxeFlags.length > 0
			) {
				this.vatType = this.newTicket.flagItemId = this.newTicket.projectFlagItems.taxeFlags[0].id;
				this.vatAmountDisabled = true;
			}
		},
		handleAmountTotalChange(payload) {
			if (!_.isNil(this.vatType)) {
				this.calculateVatAmount(this.vatType);
			}
		},
		calculateVatAmount(id) {
			const vatValue = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +id === +tax.item.id)[0].item.factor;

			let amount = this.newTicket.amountTotal / ((vatValue + 100) / 100);
			amount = parseFloat(((amount * 100) / 100).toFixed(2));
			this.newTicket.amount = amount;

			let amountVat = parseFloat((this.newTicket.amountTotal - amount).toFixed(2));
			this.newTicket.amountVat = amountVat;
		},
		handleImageUploadError(error) {
			console.error({ error });
			this.ocrMessageError = '';
			const { graphQLErrors } = error;
			if (!_.isNil(graphQLErrors) && !_.isUndefined(graphQLErrors) && graphQLErrors.length > 0) {
				this.ocrMessageError = graphQLErrors[0].message;
			}
			this.ocrIssueModal = true;
		}
	},
	validations() {
		let pattern = {
			description: {
				required,
				min: minLength(4)
			}
		};

		if (this.useComponent) {
			pattern = {
				...pattern,
				userToEncodedId: {
					required,
					decimal,
					min: greaterThanZero
				}
			};
		}

		pattern = {
			...pattern,
			amountTotal: {
				required,
				decimal
				// amountTotalValid
			}
		};

		return {
			newTicket: {
				...pattern
			}
		};
	}
};
</script>
<style lang="scss">
.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

/* #ocr-issue-modal .header-class-modal-doc-package h5 {
	color: #fff;
} */
</style>
