<template>
	<div class="px-0" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<!-- <pre>{{ mileage }}</pre> -->
		<b-form-group v-if="this.$props.useComponent" :label="FormMSG(76, 'Select Person')" label-for="new-expense_ticket-ref">
			<b-form-select
				@change="handleSelectUser"
				size="md"
				v-model="mileage.userToEncodedId"
				:options="users"
				value-field="value"
				text-field="name"
				disabled-field="notEnabled"
				:disabled="disableSelectUser"
				:class="{ 'is-invalid': $v.mileage.userToEncodedId.$error }"
			/>
			<div v-if="$v.mileage.userToEncodedId.$error" class="invalid-feedback">
				{{ FormMSG(45, 'Please, the user to encoded is required') }}
			</div>
		</b-form-group>
		<b-row>
			<b-col sm="4">
				<date-description :edit-data="editData" @change="handleDateAndDescriptionChanges" />
			</b-col>
			<b-col sm="8">
				<b-form-group :label="FormMSG(9989898, 'Description')" label-for="new-expense_description">
					<b-form-textarea
						id="new-expense_description"
						v-model="mileage.description"
						rows="3"
						class="mt-0"
						:class="{
							'is-invalid': $v.mileage.description.$error
						}"
					/>
					<div v-if="$v.mileage.description.$error" class="invalid-feedback">
						{{ FormMSG(43, 'Please, reference is required') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<!-- ADD DEPARTMENT FOR CO2 CATEGORY -->
		<b-row>
			<b-col sm="12">
				<department-selection
					:edit-data="editData"
					:form-type="formType"
					:is-new="isNew"
					hide-transportation-mean
					remove-dep-zero
					category-for-transportation
					department-desktop-size="6"
					category-desktop-size="6"
					:is-submitted="isSubmitted"
					@change="handleDepartmentAndCatChange"
					@department:selector:invalid="isInvalidateFields"
					for-expense
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12">
				<transport-type-selector
					ref="transportTypeRef"
					:data-additional-detail="mileage.additionalSubType"
					:data-detail="mileage.subType"
					:data-type="mileage.subCategory"
					hide-special-vehicles
					hide-boat-type
					hide-freight-type
					hide-menues-not-two-wheels
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12">
				<google-distance
					:distance-valid="$v.mileage.km.$model > 0"
					:edit-data="mileage"
					:to-location-label="FormMSG(2, 'To location')"
					:from-location-label="FormMSG(1, 'From location')"
					:to-location-error-message="FormMSG(46, 'Arrival point, is required')"
					:from-location-error-message="FormMSG(47, 'Starting point, is required')"
					:inline-validator="true"
					:is-submitted="isSubmitted"
					@google-distance:selector-from:invalid="isInvalidGoogleFrom"
					@google-distance:selector-to:invalid="isInvalidGoogleTo"
					@google-distance:loading="calculateLoading"
					@change="handleDistanceChanges"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="6">
				<b-form-group :label="FormMSG(3, 'Travel distance')" label-for="new-expense_travel-distance">
					<b-input-group>
						<b-form-input
							id="new-expense_travel-distance"
							type="number"
							min="0"
							step="0.001"
							v-model="$v.mileage.km.$model"
							placeholder="0"
							:readonly="loading"
							@change="handleCategoryChange"
							:class="{
								'is-invalid': $v.mileage.km.$error
							}"
						/>
						<b-input-group-append>
							<b-input-group-text>
								<span class="font-weight-bold" style="color: #06263e">{{ distanceUnit }}</span>
							</b-input-group-text>
						</b-input-group-append>
						<div v-if="$v.mileage.km.$error" class="invalid-feedback">
							{{ FormMSG(44, 'Please, value must be greater than 0') }}
						</div>
					</b-input-group>
					<div class="info-message" id="tooltip-distance" @click="openInfoDistance">
						<span><info :size="16" /></span>
						<span class="label">{{ FormMSG(7, 'Info message') }}</span>
					</div>
					<b-popover target="tooltip-distance" :show.sync="popInfoDistance" placement="bottom">
						{{ FormMSG(8, 'You can always change this unit in your project configuration') }} ({{ distanceUnit }})
					</b-popover>
				</b-form-group>
			</b-col>
			<b-col sm="6">
				<b-form-group>
					<label>{{ this.FormMSG(5, 'Your green impact') }} (Kg CO<sub>2</sub>)</label>
					<b-input-group>
						<b-form-input id="new-expense_travel-kgCoTwo" type="number" v-model="mileage.kgCoTwo" placeholder="0" disabled />
						<b-input-group-append>
							<b-input-group-text>
								<component :is="getLucideIcon('Sprout')" color="#00A09C" :size="20" :stroke-width="2.25" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { objReducer, isNil, greaterThanZero } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import DateDescription from '@/components/ExpenseService/DateSelection';
import GoogleDistance from '@/components/GoogleDistance';
import { GetKmGreenImpact } from '@/cruds/carbon.crud';
import { Info } from 'lucide-vue';
import { store } from '@/store';
import { recalculateDistanceValueByUnit, recalculateDistanceValueToKm } from '@/shared/google/helpers';
import DepartmentSelection from '@/components/DepartmentsSelection';
import { getUsers } from '@/cruds/users.crud';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import TransportTypeSelector from '../../modules/transports/components/TransportTypeSelector.vue';
import _ from 'lodash';

const defaultMileage = {
	km: 0,
	fromLocation: '',
	toLocation: '',
	description: '',
	kgCoTwo: 0,
	subCategory: 0,
	department: 0,
	category: 0,
	subType: 0,
	additionalSubType: 0
};

export default {
	name: 'CreateNewExpenseMileageComponent',
	components: {
		TransportTypeSelector,
		DateDescription,
		GoogleDistance,
		Info,
		DepartmentSelection
	},
	mixins: [languageMessages, globalMixin, validationMixin],
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		formType: {
			type: Number,
			required: false,
			default: 2
		},
		isSubmitted: {
			type: Boolean,
			required: true,
			default: false
		},
		isNew: {
			type: Boolean
		},
		transportTypeFromVehicule: {
			type: Object,
			required: false,
			default: () => ({})
		}
	},
	data() {
		return {
			mileage: Object.assign({}, defaultMileage),
			dontRecalculate: false,
			incRecalculate: 0,
			popInfoDistance: false,
			user: {
				value: 0,
				name: ''
			},
			disableSelectUser: false,
			users: [{ value: 0, name: 'Select ...' }],
			allowExpenseEntryOutsideContract: 0,
			messageAllowExpenseEntryOutsideContract: '',
			dateSelected: moment(Date.now()).format('YYYY-MM-DD') + 'T00:00:00Z',
			showPopUp: false,
			showAlert: false,
			departmentSelectionValid: false,
			isInvalidGoogle: false,
			loading: false,
			invalidGoogleFrom: false,
			invalidGoogleTo: false
		};
	},
	computed: {
		optionsForTransportMean() {
			let result = this.FormMenu(905); // 1124 is without planes

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		setAllowExpenseEntryOutsideContract() {
			return store.getCurrentProjectConfig().allowExpenseEntryOutsideContract;
		}
	},
	watch: {
		editData: {
			handler(newVal) {
				if (!_.isNil(newVal)) {
					this.mileage = {
						...newVal,
						...objReducer(
							[
								'description',
								'originalKm',
								'km',
								'originalFromLocation',
								'fromLocation',
								'originalToLocation',
								'toLocation',
								'kgCoTwo',
								'subCategory',
								'subType',
								'additionalSubType',
								'roundTrip'
							],
							this.editData
						),
						description: this.editData.description
					};
				}
			},
			immediate: true,
			deep: true
		},
		mileage: {
			handler(mileage) {
				if (!_.isNil(mileage)) {
					this.$v.$touch();
					this.isInvalidateFields(this.departmentSelectionValid);
					this.$emit(
						'change',
						{
							...mileage,
							km: parseFloat(mileage.km),
							subCategory: parseInt(mileage.subCategory),
							subType: parseInt(mileage.subType),
							additionalSubType: parseInt(mileage.additionalSubType)
						},
						this.$v.$invalid || this.$refs.transportTypeRef.isInvalid() || this.isInvalidGoogle
					);
				}
			},
			deep: true
		},
		transportTypeFromVehicule: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.mileage.subCategory = newVal.subCategory;
					this.mileage.subType = newVal.subType;
					this.mileage.additionalSubType = newVal.additionalSubType;
				}
			}
		},
		deep: true
	},
	async created() {
		await this.getAllUsers();
		this.setUser();
		this.handleSelectUser(this.mileage.userToEncodedId);
	},
	methods: {
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.mileage.subCategory = type;
			this.mileage.subType = detail === null ? 0 : detail;
			this.mileage.additionalSubType = additionalDetail === null ? 0 : additionalDetail;
			this.updateKgCO();
		},
		handleSelectUser(user) {
			this.$emit('changeUser', user);
		},
		setUser() {
			if (this.editData) {
				this.disableSelectUser = true;
				this.mileage.userToEncodedId = parseInt(this.editData.user.id, 10);
			} else {
				this.mileage.userToEncodedId = store.state.myProfile.id;
			}
		},
		async getAllUsers() {
			let forExpense = true;
			this.users = [
				...this.users,
				...[...(await getUsers({ myProject: true }, 0, -1, 0, 0, '', '', null, null, forExpense))].map((_user) => {
					return { value: _user.id, name: _user.fullName };
				})
			];
			if (this.useComponent) {
				this.users = this.users.filter((item) => +item.value !== +store.state.myProfile.id);
			}
			let isResult = this.users.find((item) => +item.value === +this.mileage.userToEncodedId);
			if (isResult === undefined) {
				this.mileage.userToEncodedId = 0;
				this.$emit('changeUser', this.mileage.userToEncodedId);
			} else {
				this.$emit('changeUser', this.mileage.userToEncodedId);
			}
		},
		handleDepartmentAndCatChange(payload) {
			this.mileage.department = payload.department;
			this.mileage.category = payload.category;
		},
		isInvalidateFields(payload) {
			this.departmentSelectionValid = payload;
			this.$emit(
				'mileage:invalid',
				payload || this.$v.$invalid || (this.$refs.transportTypeRef ? this.$refs.transportTypeRef.isInvalid() : false) || this.isInvalidGoogle
			);
		},
		handleDateAndDescriptionChanges(obj) {
			this.mileage = {
				...this.mileage,
				...objReducer(['date'], obj)
			};
			const _o = objReducer(['date'], obj);
			this.dateSelected = _o.date;
		},
		/**
		 * @param {Object} options
		 */
		async updateKgCO() {
			if (recalculateDistanceValueToKm(this.mileage.km) > 0) {
				this.mileage.kgCoTwo = await GetKmGreenImpact(recalculateDistanceValueToKm(this.mileage.km), this.mileage.subCategory, this.mileage.subType);
			} else {
				this.mileage.kgCoTwo = 0;
			}
		},
		handleCategoryChange() {
			this.updateKgCO();
		},
		handleDistanceChanges(payload) {
			this.mileage = {
				...this.mileage,
				...objReducer(['fromLocation', 'toLocation', 'km', 'roundTrip'], payload)
			};
			this.setOriginalKm();
			this.updateKgCO();
		},
		setOriginalKm() {
			if (
				!_.isNil(this.mileage.originalKm) &&
				this.mileage.originalKm > 0 &&
				this.mileage.originalKm !== this.mileage.km &&
				this.mileage.originalFromLocation === this.mileage.fromLocation &&
				this.mileage.originalToLocation === this.mileage.toLocation
			) {
				this.mileage.originalKm = this.mileage.km;
				this.mileage.km = recalculateDistanceValueByUnit(this.mileage.originalKm);
			}
		},
		openInfoDistance() {
			this.popInfoDistance = !this.popInfoDistance;
		},
		isInvalidGoogleFrom(payload) {
			this.invalidGoogleFrom = payload;
			this.isInvalidGoogle = this.invalidGoogleFrom || this.invalidGoogleTo;
			this.$emit('mileage:invalid', this.isInvalidGoogle);
		},
		isInvalidGoogleTo(payload) {
			this.invalidGoogleTo = payload;
			this.isInvalidGoogle = this.invalidGoogleFrom || this.invalidGoogleTo;
			this.$emit('mileage:invalid', this.isInvalidGoogle);
		},
		calculateLoading(payload) {
			this.loading = payload;
		}
	},
	validations() {
		let pattern = {
			description: {
				required,
				min: minLength(4)
			},
			km: {
				required,
				decimal,
				min: greaterThanZero
			}
		};

		if (this.useComponent) {
			pattern = {
				...pattern,
				userToEncodedId: {
					required,
					decimal,
					min: greaterThanZero
				}
			};
		}

		return {
			mileage: {
				...pattern
			}
		};
	}
};
</script>
<style lang="scss">
.info-message {
	display: inline-flex;
	letter-spacing: 0.01em;
	font-weight: 500;
	color: #225cbd;
	font-size: 13px;
	margin-top: 4px;
	cursor: pointer;

	.label {
		margin-left: 4px;
		margin-top: 1px;
	}
}
</style>
