var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-0",
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
    },
    [
      this.$props.useComponent
        ? _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(76, "Select Person"),
                "label-for": "new-expense_ticket-ref",
              },
            },
            [
              _c("b-form-select", {
                class: { "is-invalid": _vm.$v.mileage.userToEncodedId.$error },
                attrs: {
                  size: "md",
                  options: _vm.users,
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "notEnabled",
                  disabled: _vm.disableSelectUser,
                },
                on: { change: _vm.handleSelectUser },
                model: {
                  value: _vm.mileage.userToEncodedId,
                  callback: function ($$v) {
                    _vm.$set(_vm.mileage, "userToEncodedId", $$v)
                  },
                  expression: "mileage.userToEncodedId",
                },
              }),
              _vm.$v.mileage.userToEncodedId.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            45,
                            "Please, the user to encoded is required"
                          )
                        ) +
                        "\n\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "4" } },
            [
              _c("date-description", {
                attrs: { "edit-data": _vm.editData },
                on: { change: _vm.handleDateAndDescriptionChanges },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "8" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(9989898, "Description"),
                    "label-for": "new-expense_description",
                  },
                },
                [
                  _c("b-form-textarea", {
                    staticClass: "mt-0",
                    class: {
                      "is-invalid": _vm.$v.mileage.description.$error,
                    },
                    attrs: { id: "new-expense_description", rows: "3" },
                    model: {
                      value: _vm.mileage.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.mileage, "description", $$v)
                      },
                      expression: "mileage.description",
                    },
                  }),
                  _vm.$v.mileage.description.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(43, "Please, reference is required")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c("department-selection", {
                attrs: {
                  "edit-data": _vm.editData,
                  "form-type": _vm.formType,
                  "is-new": _vm.isNew,
                  "hide-transportation-mean": "",
                  "remove-dep-zero": "",
                  "category-for-transportation": "",
                  "department-desktop-size": "6",
                  "category-desktop-size": "6",
                  "is-submitted": _vm.isSubmitted,
                  "for-expense": "",
                },
                on: {
                  change: _vm.handleDepartmentAndCatChange,
                  "department:selector:invalid": _vm.isInvalidateFields,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c("transport-type-selector", {
                ref: "transportTypeRef",
                attrs: {
                  "data-additional-detail": _vm.mileage.additionalSubType,
                  "data-detail": _vm.mileage.subType,
                  "data-type": _vm.mileage.subCategory,
                  "hide-special-vehicles": "",
                  "hide-boat-type": "",
                  "hide-freight-type": "",
                  "hide-menues-not-two-wheels": "",
                },
                on: {
                  "transportation-type-selector:change":
                    _vm.handleTransportationTypeSelectorChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c("google-distance", {
                attrs: {
                  "distance-valid": _vm.$v.mileage.km.$model > 0,
                  "edit-data": _vm.mileage,
                  "to-location-label": _vm.FormMSG(2, "To location"),
                  "from-location-label": _vm.FormMSG(1, "From location"),
                  "to-location-error-message": _vm.FormMSG(
                    46,
                    "Arrival point, is required"
                  ),
                  "from-location-error-message": _vm.FormMSG(
                    47,
                    "Starting point, is required"
                  ),
                  "inline-validator": true,
                  "is-submitted": _vm.isSubmitted,
                },
                on: {
                  "google-distance:selector-from:invalid":
                    _vm.isInvalidGoogleFrom,
                  "google-distance:selector-to:invalid": _vm.isInvalidGoogleTo,
                  "google-distance:loading": _vm.calculateLoading,
                  change: _vm.handleDistanceChanges,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(3, "Travel distance"),
                    "label-for": "new-expense_travel-distance",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.$v.mileage.km.$error,
                        },
                        attrs: {
                          id: "new-expense_travel-distance",
                          type: "number",
                          min: "0",
                          step: "0.001",
                          placeholder: "0",
                          readonly: _vm.loading,
                        },
                        on: { change: _vm.handleCategoryChange },
                        model: {
                          value: _vm.$v.mileage.km.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.mileage.km, "$model", $$v)
                          },
                          expression: "$v.mileage.km.$model",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c("b-input-group-text", [
                            _c(
                              "span",
                              {
                                staticClass: "font-weight-bold",
                                staticStyle: { color: "#06263e" },
                              },
                              [_vm._v(_vm._s(_vm.distanceUnit))]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm.$v.mileage.km.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    44,
                                    "Please, value must be greater than 0"
                                  )
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "info-message",
                      attrs: { id: "tooltip-distance" },
                      on: { click: _vm.openInfoDistance },
                    },
                    [
                      _c("span", [_c("info", { attrs: { size: 16 } })], 1),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(_vm.FormMSG(7, "Info message"))),
                      ]),
                    ]
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "tooltip-distance",
                        show: _vm.popInfoDistance,
                        placement: "bottom",
                      },
                      on: {
                        "update:show": function ($event) {
                          _vm.popInfoDistance = $event
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              8,
                              "You can always change this unit in your project configuration"
                            )
                          ) +
                          " (" +
                          _vm._s(_vm.distanceUnit) +
                          ")\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "6" } },
            [
              _c(
                "b-form-group",
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(this.FormMSG(5, "Your green impact")) + " (Kg CO"
                    ),
                    _c("sub", [_vm._v("2")]),
                    _vm._v(")"),
                  ]),
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "new-expense_travel-kgCoTwo",
                          type: "number",
                          placeholder: "0",
                          disabled: "",
                        },
                        model: {
                          value: _vm.mileage.kgCoTwo,
                          callback: function ($$v) {
                            _vm.$set(_vm.mileage, "kgCoTwo", $$v)
                          },
                          expression: "mileage.kgCoTwo",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c(_vm.getLucideIcon("Sprout"), {
                                tag: "component",
                                attrs: {
                                  color: "#00A09C",
                                  size: 20,
                                  "stroke-width": 2.25,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }