<template>
	<div class="new_expense_date_wrapper">
		<div class="bootstrap-date-selection">
			<label for="new-expense_amount-date">{{ FormMSG(1, 'Choose a date') }}</label>
			<!-- TODO: set default date to today -->
			<b-form-datepicker id="new-expense_amount-date" v-model="element.date" :locale="currentLang" class="mb-0" style="margin-top: -6px" />
		</div>
	</div>
</template>

<script>
// import { mapGetters } from 'vuex';
import { objReducer, isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';

const todayDate = new Date().toJSON().slice(0, 10);
const extraDate = 'T00:00:00Z';

export default {
	name: 'CreateExpenseDateAndDescription',
	mixins: [languageMessages, GlobalMixin],
	props: {
		editData: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			element: {
				// description: '',
				date: todayDate
			}
		};
	},
	created() {
		this.initEditData();
	},
	watch: {
		element: {
			handler(val) {
				if (!_.isNil(val) && !_.isUndefined(val.date)) {
					this.$emit('change', {
						// description: val.description,
						date: val.date.length === 10 ? val.date + extraDate : val.date
					});
				}
			},
			immediate: true,
			deep: true
		},
		editData: {
			handler() {
				this.initEditData();
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		initEditData() {
			if (isNil(this.editData)) return;
			// const _E = objReducer(['description', 'date'], this.editData);
			const _E = objReducer(['date'], this.editData);
			this.element = {
				...this.element,
				..._E,
				date: this.getDateString(_E)
			};
		},

		/**
		 * @param {Object}
		 * @return {String}
		 */
		getDateString({ date }) {
			if (isNil(date)) return;
			return date.substring(0, date.indexOf('T'));
		}
	}
};
</script>
