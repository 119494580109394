<template>
	<li class="po-item-not-consumed-item">
		<b-card :class="rendCardClass" @click="handleSelectItem">
			<b-row>
				<b-col md="1">
					<span class="name">
						<b-form-checkbox size="lg" id="toEmail" v-model="item.selected" :disabled="true"></b-form-checkbox>
					</span>
				</b-col>
				<b-col md="7">
					<span class="name">
						<b v-html="item.name" />
					</span>
				</b-col>
				<b-col md="4">
					<span class="total">{{ rendTotal }}</span>
				</b-col>
			</b-row>
		</b-card>
	</li>
</template>

<script>
import { rendCurrency } from '~helpers';

export default {
	name: 'PoItemsNotConsumItemComponent',
	props: {
		item: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isSelected() {
			return this.item.selected;
		},

		/**
		 * @return {Object}
		 */
		rendCardClass() {
			return {
				'is-selected': this.isSelected
			};
		},

		/**
		 * @return {String}
		 */
		rendTotal() {
			return rendCurrency(this.item.total);
		}
	},
	methods: {
		handleSelectItem() {
			this.$emit('select', this.item);
		}
	}
};
</script>
