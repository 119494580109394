<template>
	<div class="new_expense_incvoice_wrapper">
		<b-form-group v-if="useComponent" :label="FormMSG(4, 'Select Person')" label-for="new-expense_ticket-ref">
			<b-form-select
				@change="handleSelectUser"
				size="md"
				v-model="user.value"
				:options="users"
				value-field="value"
				text-field="name"
				disabled-field="notEnabled"
				:disabled="disableSelectUser"
				:class="{ 'is-invalid': $v.user.value.$error }"
			/>
			<div v-if="$v.user.value.$error" class="invalid-feedback">
				{{ FormMSG(5, 'Please, the user to encoded is required') }}
			</div>
		</b-form-group>
		<b-button block variant="outline-secondary" @click="useWithoutPO">
			{{ FormMSG(1, 'Create Invoice without a purchase order') }}
		</b-button>
		<b-row>
			<b-col md="12">
				<div style="padding-top: 20px">
					<v-select
						v-if="mapPurchaseOrders.length > 0"
						:placeholder="FormMSG(3, 'Search for a purchase order')"
						:options="mapPurchaseOrders"
						@input="handlePoChange"
					/>
					<p v-else style="text-align: center">{{ FormMSG(2, 'No purchase order') }}...</p>
				</div>
			</b-col>
			<b-col v-if="isPoSelected" md="12" style="padding-top: 20px">
				<b-card>
					<b-button block variant="outline-secondary" @click="selectAllPoItems">
						{{ rendAllSelectionTxt() }}
					</b-button>
					<br />
					<ul class="poitem-not-consumed-selected-wrapper">
						<PoItemsNotConsumItem v-for="(item, i) in poListsItems" :key="i" :item="item" @select="handlePoItemSelection" />
					</ul>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getPoItems } from '@/cruds/po-item.crud';
import { isNil, greaterThanZero } from '~utils';
import languageMessages from '@/mixins/languageMessages';
import PoItemsNotConsumItem from '@/components/ExpenseService/PoItemsNotConsumItem';
import { getUsers } from '@/cruds/users.crud';
import { store } from '@/store';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';

export default {
	name: 'CreateNewExpenseInvoiceForm',
	mixins: [languageMessages, validationMixin],
	components: {
		PoItemsNotConsumItem
	},
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		expenseType: {
			type: Number,
			required: false,
			default: 1
		}
	},
	data() {
		return {
			poSelected: null,
			allPoItemsSelected: false,
			indeterminatePoItems: false,
			poListsItems: [],
			user: {
				value: 0,
				name: ''
			},
			disableSelectUser: false,
			users: [{ value: 0, name: 'Select ...' }]
		};
	},
	created() {
		this.$nextTick(async () => {
			this.$v.$touch();
			await this.getPurchaseOrdersNotConsumed();
			await this.getAllUsers();
			this.handleSelectUser(this.user.value);
			this.setUser();
		});
	},
	computed: {
		...mapGetters({
			purchaseOrders: 'purchaces/purchaseOrdersNotConsumed'
		}),
		mapPurchaseOrders() {
			return this.purchaseOrders.map((po) => ({
				code: po.id,
				label: `[${po.amount}] ${po.description} (${po.departmentName})`
			}));
		},
		/**
		 * @return {Boolean}
		 */
		isPoSelected() {
			return !isNil(this.poSelected);
		}
	},
	watch: {
		poListsItems: {
			handler(items) {
				this.watchPoItemsList(items);
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		...mapActions({
			getPurchaseOrdersNotConsumed: 'purchaces/getPurchaseOrdersNotConsumed'
		}),
		handleSelectUser(value) {
			this.$emit('changeUser', value);
			return;
		},
		setUser() {
			if (this.editData && this.useComponent === true) {
				this.disableSelectUser = true;
				this.user.value = this.editData.user.id;
				this.$emit('changeUser', this.user.value);
				return;
			} else {
				this.user.value = store.state.myProfile.id;
				this.$emit('changeUser', this.user.value);
				return;
			}
		},
		async getAllUsers() {
			let forExpense = true;
			this.users = [
				...this.users,
				...[...(await getUsers({ myProject: true }, 0, -1, 0, 0, '', '', null, null, forExpense))].map((_user) => {
					return { value: _user.id, name: _user.fullName };
				})
			];
			let isResult = this.users.find((item) => +item.value === +this.user.value);
			if (isResult === undefined) {
				this.user.value = 0;
				this.$emit('changeUser', this.user.value);
			}
			this.$emit('changeUser', this.user.value);
		},
		async handlePoChange({ code }) {
			this.poSelected = code;
			const poListItems = await getPoItems(code, true);
			this.poListsItems = poListItems.map((poi) => ({
				...poi,
				selected: true
			}));
			this.$emit('init-new-ref', false);
		},
		/**
		 * @param {Object} id - PoItemID
		 */
		handlePoItemSelection(item) {
			//console.log("handlePoItemSelection",item);
			if (isNil(item)) return;
			const index = this.poListsItems.indexOf(item);
			const _v = this.poListsItems[index].selected;
			this.poListsItems[index].selected = !_v;
		},
		useWithoutPO() {
			this.$emit('init-new-ref', true);
		},
		rendAllSelectionTxt() {
			const $t = this.FormMSG;
			return this.allPoItemsSelected ? $t(232323, 'Un-select All') : $t(24242424, 'Select All');
		},
		selectAllPoItems() {
			this.allPoItemsSelected = !this.allPoItemsSelected;
			//console.log("in selectAllPoItems",this.allPoItemsSelected);
			const _s = this.allPoItemsSelected;
			this.poListsItems.forEach((item, i) => {
				this.poListsItems[i].selected = _s;
			});
		},
		/**
		 * @param {Array} items
		 */
		watchPoItemsList(items) {
			//console.log("in watchPoItemsList ",items);
			let countSelected = 0;
			const poItemsIds = [];

			items.forEach((e) => {
				if (!e.selected) return;
				countSelected++;
				poItemsIds.push(parseInt(e.id));
			});
			// console.log("in watchPoItemsList countSelected ",countSelected);
			if (countSelected === 0) {
				this.indeterminatePoItems = false;
				this.allPoItemsSelected = false;
			} else if (countSelected === items.length) {
				this.indeterminatePoItems = false;
				this.allPoItemsSelected = true;
			} else {
				this.indeterminatePoItems = true;
				this.allPoItemsSelected = false;
			}
			//console.log("in watchPoItemsList this.allPoItemsSelected ",this.allPoItemsSelected);
			this.$emit('selected', {
				poId: this.poSelected,
				poItemsIds
			});
		}
	},
	validations() {
		return {
			user: {
				value: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};
	}
};
</script>
