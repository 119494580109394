var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new_expense_incvoice_wrapper" },
    [
      _vm.useComponent
        ? _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.FormMSG(4, "Select Person"),
                "label-for": "new-expense_ticket-ref",
              },
            },
            [
              _c("b-form-select", {
                class: { "is-invalid": _vm.$v.user.value.$error },
                attrs: {
                  size: "md",
                  options: _vm.users,
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "notEnabled",
                  disabled: _vm.disableSelectUser,
                },
                on: { change: _vm.handleSelectUser },
                model: {
                  value: _vm.user.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "value", $$v)
                  },
                  expression: "user.value",
                },
              }),
              _vm.$v.user.value.$error
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            5,
                            "Please, the user to encoded is required"
                          )
                        ) +
                        "\n\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-button",
        {
          attrs: { block: "", variant: "outline-secondary" },
          on: { click: _vm.useWithoutPO },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.FormMSG(1, "Create Invoice without a purchase order")
              ) +
              "\n\t"
          ),
        ]
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c(
              "div",
              { staticStyle: { "padding-top": "20px" } },
              [
                _vm.mapPurchaseOrders.length > 0
                  ? _c("v-select", {
                      attrs: {
                        placeholder: _vm.FormMSG(
                          3,
                          "Search for a purchase order"
                        ),
                        options: _vm.mapPurchaseOrders,
                      },
                      on: { input: _vm.handlePoChange },
                    })
                  : _c("p", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(
                        _vm._s(_vm.FormMSG(2, "No purchase order")) + "..."
                      ),
                    ]),
              ],
              1
            ),
          ]),
          _vm.isPoSelected
            ? _c(
                "b-col",
                { staticStyle: { "padding-top": "20px" }, attrs: { md: "12" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { block: "", variant: "outline-secondary" },
                          on: { click: _vm.selectAllPoItems },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.rendAllSelectionTxt()) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c("br"),
                      _c(
                        "ul",
                        { staticClass: "poitem-not-consumed-selected-wrapper" },
                        _vm._l(_vm.poListsItems, function (item, i) {
                          return _c("PoItemsNotConsumItem", {
                            key: i,
                            attrs: { item: item },
                            on: { select: _vm.handlePoItemSelection },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }